<template>
  <button  class="btn-text-link " @click="$emit('click')">
    <slot name="prefixIcon" />
    <span>{{ title }}</span>
    <slot class='btn-text-link__suffix-icon' name="suffixIcon" />
  </button>
</template>

<script>
export default {
  name: 'LinkButton',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
