<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 8H14" stroke="#FAA433" stroke-width="1.5" stroke-linejoin="round" />
    <path
      d="M10 3L15 8L10 13"
      stroke="#FAA433"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowRightIcon',
};
</script>
