<template>
  <div class="post-paid-page container my-5">
    <transition name="component-fade" mode="out-in">
      <div v-if="loading" key="loader" class="f-center h-100 mt-auto">
        <div>
          <Loader />
        </div>
      </div>
      <div v-else>
        <div class="post-paid-page__header">
          <p>{{ headerLabel }}</p>
        </div>
        <div class="post-paid-page__icon">
          <img :src="icon" alt="иконка" />
        </div>
        <div class="post-paid-page__tariff-label">
          <p v-if="isSuccess">
            {{ $t('supportText.youConnected') }}
            <span>
              {{ tariffName }}
            </span>
          </p>
          <p v-else-if="isFailed">{{ $t('supportText.payOneMoreTime') }}</p>
          <p v-else>{{ $t('supportText.haveToWait') }}</p>
        </div>
        <div
          class="post-paid-page__subscription-label"
          :class="{
            'post-paid-page__subscription-label--custom-max-width': isSuccess,
          }"
        >
          <p>{{ subLabel }}</p>
        </div>
        <LinkButton v-if="!isPending" :title="linkLabel" @click="handleButton">
          <template #suffixIcon>
            <ArrowRightIcon />
          </template>
        </LinkButton>
      </div>
    </transition>
  </div>
</template>

<script>
import LinkButton from '@/components/Buttons/LinkButton.vue';
import Loader from '@/components/Loader/Loader.vue';
import ArrowRightIcon from '@/components/Icons/ArrowRightIcon.vue';
import SubscriptionService from '@/services/subscription.service';
import AuthService from '@/services/auth.service';
import { mapGetters } from 'vuex';

export default {
  name: 'PostPaidPage',
  components: {
    LinkButton,
    Loader,
    ArrowRightIcon,
  },
  data: () => ({
    loading: true,
    isSuccess: false,
    isPending: false,
    isFailed: false,
    interval: null,
  }),
  created() {
    this.checkPayment();
  },
  computed: {
    ...mapGetters({ userTariff: 'auth/getUserTariff' }),
    headerLabel() {
      if (this.isSuccess) {
        return this.$i18n.t('header.thanksForSubscription');
      }
      if (this.isFailed) {
        return this.$i18n.t('supportText.paymentFailed');
      }
      return this.$i18n.t('supportText.paymentInProgress');
    },
    subLabel() {
      if (this.isSuccess) {
        const hasProlongation = this.userTariff.prolongation?.id !== 0;

        if (hasProlongation) {
          return `${this.$t('supportText.nextSubscriptionWillBeValid')} ${
            this.userTariff.expiresAt
          }`;
        }
        return `${this.$i18n.t('supportText.subscriptionWillBeValid')} ${
          this.userTariff.expiresAt
        }`;
      }
      if (this.isFailed) {
        return this.$i18n.t('supportText.shitHappens');
      }
      return this.$i18n.t('supportText.stayOnPage');
    },
    icon() {
      if (this.isSuccess) {
        return require('@/assets/images/svg/done-icon.svg');
      }
      if (this.isFailed) {
        return require('@/assets/images/svg/unavailable-icon.svg');
      }
      return require('@/assets/images/svg/in-progress-icon.svg');
    },
    linkLabel() {
      return this.isSuccess ? this.$i18n.t('links.goToManagerMainPage') : this.$i18n.t('links.goToTariff');
    },
    tariffName() {
      const hasProlongation = this.userTariff.prolongation?.id !== 0;
      return hasProlongation
        ? this.$i18n.t(this.userTariff.prolongation.name)
        : this.$i18n.t(this.userTariff.name);
    },
  },
  methods: {
    checkPayment() {
      SubscriptionService.checkPaymentStatus()
        .then(this.handleStatus)
        .then(async () => {
          if (this.isSuccess) {
            await AuthService.whoAmI();
          }
        })
        .catch(() => {
          this.isSuccess = false;
          this.isFailed = true;
          this.isPending = false;
        })
        .finally(() => (this.loading = false));
    },
    handleButton() {
      if (this.isSuccess) {
        this.$router.push('/programs');
      }
      if (this.isFailed) {
        this.$router.push({ name: 'TariffsDetailed' });
      }
    },
    handleStatus({ status }) {
      switch (status) {
        case 'succeeded':
          // eslint-disable-next-line no-sequences
          return (this.isSuccess = true), (this.isFailed = false), (this.isPending = false);
        case 'canceled':
          // eslint-disable-next-line no-sequences
          return (this.isSuccess = false), (this.isFailed = true), (this.isPending = false);
        default:
          // eslint-disable-next-line no-sequences
          return (this.isSuccess = false), (this.isFailed = false), (this.isPending = true);
      }
    },
  },
  watch: {
    isPending(bool) {
      if (bool) {
        this.interval = setInterval(this.checkPayment, 20000);
      } else clearInterval(this.interval);
    },
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
</script>
